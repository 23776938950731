import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD39rtDZ4P1wGf8BqNaK6PYTorK-Zvo9CI',
  authDomain: 'disney-plaza.firebaseapp.com',
  projectId: 'disney-plaza',
  storageBucket: 'disney-plaza.appspot.com',
  messagingSenderId: '564919091623',
  appId: '1:564919091623:web:ef8eae8d513f0f39a13682',
  measurementId: 'G-53J86T3TKW',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
