import React, { lazy, Suspense, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from './utils/firebaseSetup';
import { Store } from './Store';

import { UPDATE_USER_INFO } from './constants/userConstants';

const Landing = lazy(() => import('./pages/Landing'));
const Admin = lazy(() => import('./components/Admin'));

export default function App() {
  const addToast = (message) => toast(message);

  const { state, dispatch } = useContext(Store);

  const { userInfo } = state;

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(
      (u) => {
        if (u) {
          firebase
            .firestore()
            .collection('users')
            .doc(u.uid)
            .get()
            .then((doc) => {
              let data = doc.data();
              data.id = doc.id;

              dispatch({
                type: UPDATE_USER_INFO,
                payload: data,
              });
            })
            .catch((e) => {
              console.log(e);
              toast.error('Algo salio mal');
            });
        } else {
        }
      },
      (e) => {
        console.log(e);
        toast.error('Algo salio mal');
      }
    );
  }, []);

  return (
    <div className="app">
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Landing addToast={addToast} />} />
            <Route path="/admin" element={<Admin addToast={addToast} />} />
            <Route />
          </Routes>
        </BrowserRouter>
      </Suspense>

      <ToastContainer />
    </div>
  );
}
